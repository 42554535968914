export default [
  {
    title: 'Admin Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Auth',
    action: 'read',
  },

  {
    title: 'Compañías',
    route: 'companiesView',
    icon: 'UsersIcon',
    resource: 'Auth',
    action: 'read',
  },
  {
    title: 'Planes',
    route: 'plansView',
    icon: 'UsersIcon',
    resource: 'Auth',
    action: 'read',
  },
  {
    title: 'Usuarios',
    route: 'usersView',
    icon: 'UsersIcon',
    resource: 'Auth',
    action: 'read',
  },

  /**
     * Company routes
     */
  {
    title: 'Dashboard',
    route: 'company-home',
    icon: 'HomeIcon',
    resource: 'Company',
    action: 'admin',
  },
  {
    title: 'Clientes',
    route: 'customersView',
    icon: 'UsersIcon',
    resource: 'CUSTOMERS',
    action: 'VIEW_CUSTOMERS',
  },
  {
    title: 'Movimientos',
    route: 'cycleMovementsView',
    icon: 'CodeIcon',
    resource: 'Company',
    action: 'admin',
  },
  {
    title: 'Cuentas',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Ahorros',
        route: 'savingAccountsView',
        icon: 'InboxIcon',
        resource: 'CUSTOMERS',
        action: 'VIEW_CUSTOMERS',
      },
      {
        title: 'Créditos',
        route: 'creditAccountsView',
        icon: 'TrendingUpIcon',
        resource: 'CUSTOMERS',
        action: 'VIEW_CUSTOMERS',
      },
    ],
  },
  {
    title: 'Configuración',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Reservas',
        route: 'reservesView',
        icon: 'PercentIcon',
        resource: 'Company',
        action: 'admin',
      },
      {
        title: 'Ciclos',
        route: 'cyclesView',
        icon: 'RepeatIcon',
        resource: 'Company',
        action: 'admin',
      },
      /* {
        title: 'Periodos',
        route: 'periodsView',
        icon: 'CalendarIcon',
        resource: 'Company',
        action: 'admin',
      }, */
      {
        title: 'Tipos de cuenta',
        route: 'accountTypesView',
        icon: 'PackageIcon',
        resource: 'Company',
        action: 'admin',
      },
      {
        title: 'Usuarios',
        route: 'usersView',
        icon: 'UserIcon',
        resource: 'USERS',
        action: 'VIEW_USERS',
      },
      /* {
        title: 'Configuración',
        route: 'configView',
        icon: 'SettingsIcon',
        resource: 'CUSTOMERS',
        action: 'CREATE_CUSTOMERS',
      }, */
    ],
  },
]
